<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
            v-model="postal_code"
            color="primary"
            append-outer-icon="mdi-send"
            filled
            clear-icon="mdi-close-circle"
            clearable
            label="Introduce tu código postal"
            type="number"
            @click:append-outer="get_coordinates"
            @keypress.enter="get_coordinates"
        ></v-text-field>
      </v-col>
    </v-row>

    <div v-if="centros.length">
      <v-layout row>
        <v-flex>
          <h1 class="text-center">Selecciona tu centro</h1>
        </v-flex>
      </v-layout>
      <v-layout class="mt-5" v-for="current_centro in centros" :key="current_centro.id">
        <v-flex class="pointer" xs12 sm6 offset-sm3 @click="go_to_centro(current_centro)">
          <v-card>
            <v-img class="imagen_centro"
                   :src="current_centro.imagen_principal_absolute_path"
                   aspect-ratio="2.75"
            ></v-img>
            <v-card-title primary-title class="d-flex justify-space-between">
              <div>
                <h3 class="headline">{{ current_centro.descripcion }}</h3>
              </div>
            <div class="pt-1">
              <h4>{{current_centro.direccion}}</h4>
            </div>
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import show_toolbar_mixin from "@/mixins/show_toolbar_mixin";
import send_stats_mixin from "../mixins/send_stats_mixin";
import get_utms from '../utils/utm_params'

export default {
  name: 'Centros',
  mixins: [show_toolbar_mixin, send_stats_mixin],
  data() {
    return {
      centros: [],
      lat: null,
      lng: null,
      postal_code: null,
      utms: get_utms()
    }
  },

  methods: {
    /**
     * Obtiene el código postal a través de la geolocalización
     */
    get_postal_code() {
      this.$store.commit('set_loading', true)

      navigator.geolocation.getCurrentPosition(
          async position => {
            this.lat = position.coords.latitude
            this.lng = position.coords.longitude

            this.postal_code = await this.$store.dispatch('common/get_postal_code', {
              lat: this.lat,
              lng: this.lng
            })
            await this.get_centros()

          },
          error => {
            this.$store.commit('set_loading', false)
          }
      )
    },

    /**
     * Obtiene las coordenadas a través de un código postal
     * @returns {Promise<void>}
     */
    async get_coordinates() {
      const coords = await this.$store.dispatch('common/get_coordinates_from_postal_code', this.postal_code)
      if (coords) {
        this.lat = coords.lat
        this.lng = coords.lng
      }
      await this.get_centros()
    },

    /**
     * Recupera todos los centros de la api
     * @returns {Promise<void>}
     */
    async get_centros() {
      this.centros = await this.$store.dispatch('centro/get_centros', {
        latitude: this.lat,
        longitude: this.lng
      })
      this.centros = this.centros.slice(0, 5)

    },

    go_to_centro(centro) {
      this.$store.commit('centro/set_centro', centro)
      this.$router.push({ name: 'centro', params: { slug: centro.slug }, query: this.utms })
      // this.$router.push({name: 'zonas'})
    }
  },

  mounted() {
    this.get_postal_code()
  }
}
</script>

<style lang="less" scoped>
.imagen_centro {
  min-height: 270px;
}
</style>
