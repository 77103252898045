import store from '@/store'

const show_toolbar_mixin = {
    mounted: function (){
        store.commit('common/set_show_toolbar', true),
        store.commit('set_show_logo', true)
    },
    destroyed: function (){
        store.commit('common/set_show_toolbar', false),
        store.commit('set_show_logo', false)

    }
}

export default show_toolbar_mixin